<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'

export default {
  name: 'routingDetail',
  data() {
    return {
      ...api.command.getState(),
      detail: {}
    }
  },
  mounted() {
    const { id } = this.$route.query
    id &&
      api.command.getDetail.call(this, {
        url: `/route/${id}`
      })
  },
  methods: {
    getForm1() {
      return {
        title: '路由信息',
        type: 'cardForm',
        gutter: 15,
        data: [
          {
            name: '路由Id',
            type: 'input',
            cols: 12,
            key: 'routeId',
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          },
          {
            name: '路由名称',
            type: 'input',
            cols: 12,
            key: 'routeName',
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          }
        ]
      }
    },
    getForm2() {
      return {
        title: '路由设置',
        type: 'cardForm',
        gutter: 15,
        data: [
          {
            name: '过滤器',
            type: 'input',
            cols: 12,
            key: 'filters'
          },
          {
            name: '排序值',
            type: 'input',
            cols: 12,
            key: 'order'
          },
          {
            name: '断言',
            type: 'input',
            cols: 12,
            key: 'predicates'
          },
          {
            name: '请求uri',
            type: 'input',
            cols: 12,
            key: 'uri',
            rules: [
              {
                required: true,
                type: 'string'
              }
            ]
          }
        ]
      }
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true, // TODO
          onClick: data => {
            api.command[this.detail.id ? 'editPost' : 'create']
              .call(this, {
                url: '/route/single',
                params: {
                  ...data
                },
                isPost: false
              })
              .then(result => {
                this.$router.push('/system/routing')
              })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            this.$router.push('/system/routing')
          }
        }
      ]
      return {
        left
      }
    }
  },
  render() {
    return <DetailFormGroup foot={this.getFoot()} form={this.detail} data={[this.getForm1(), this.getForm2()]} />
  }
}
</script>
